<template>
    <div class="message-container">
        <div class="wrap-header">
            <el-tabs
                v-model="activeName"
                @tab-click="handleClick"
            >
                <el-tab-pane
                    label="消息通知"
                    name="0"
                ></el-tab-pane>
                <el-tab-pane
                    label="系统公告"
                    name="1"
                ></el-tab-pane>
                <el-tab-pane
                    label="订阅信息"
                    name="2"
                ></el-tab-pane>
            </el-tabs>
        </div>
        <div class="mes-nav wrap-cotnent">
            <div v-if="activeName=='0'">
                <!-- 未读信息数 -->
                <div
                    v-show="tongzhiunReadNum != 0"
                    class="unRead"
                >未读消息数：{{tongzhiunReadNum}}</div>
                <div v-if="tongzhiList.length > 0">
                    <!-- 消息单元格 -->
                    <div
                        v-for="(item,index) in tongzhiList"
                        :key="index"
                        class="message-cell pointer"
                        @click="showMessage(item.path)"
                    >
                        <span :class="item.status == 1 ? 'hasRead' : 'unRead' ">{{getStatu(item.status)}}</span>
                        <span class="message-title pointer"> {{item.title}}</span>
                        <span class="message-time pointer"> {{item.createTime}}</span>
                    </div>
                </div>
                <NoDataView
                    v-else
                    dataState="noData"
                ></NoDataView>
            </div>
            <div v-if="activeName=='1'">
                <!-- 未读信息数 -->
                <div
                    v-show="tongzhiunReadNum != 0"
                    class="unRead"
                >未读消息数：{{tongzhiunReadNum}}</div>
                <div v-if="gonggaoList.length > 0">
                    <!-- 消息单元格 -->
                    <div
                        v-for="(item,index) in gonggaoList"
                        :key="index"
                        class="message-cell pointer"
                        @click="showMessage(item.path)"
                    >
                        <span :class="item.status == 1 ? 'hasRead' : 'unRead' ">{{getStatu(item.status)}}</span>
                        <span class="message-title pointer"> {{item.title}}</span>
                        <span class="message-time pointer"> {{item.createTime}}</span>
                    </div>
                </div>
                <NoDataView
                    v-else
                    dataState="noData"
                ></NoDataView>
            </div>
            <div v-if="activeName=='2'">
                <!-- 未读信息数 -->
                <div
                    v-show="tongzhiunReadNum != 0"
                    class="unRead"
                >未读消息数：{{tongzhiunReadNum}}</div>
                <div v-if="dingyueList.length > 0">
                    <!-- 消息单元格 -->
                    <div
                        v-for="(item,index) in dingyueList"
                        :key="index"
                        class="message-cell pointer"
                        @click="showMessage(item.path)"
                    >
                        <span :class="item.status == 1 ? 'hasRead' : 'unRead' ">{{getStatu(item.status)}}</span>
                        <span class="message-title pointer"> {{item.title}}</span>
                        <span class="message-time pointer"> {{item.createTime}}</span>
                    </div>
                </div>
                <NoDataView
                    v-else
                    dataState="noData"
                ></NoDataView>
            </div>
            <el-pagination
                hide-on-single-page
                style="margin-top:16px;"
                layout="total, prev, pager, next"
                background
                :total="total"
                :page-size="pageSize"
                @current-change="changePageNum"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import {
    mapGetters
} from "vuex"
import NoDataView from '@/components/nodata-view'

export default {
    components: {
        NoDataView,
    },
    data() {
        return {
            // 通知栏小铃铛的消息列表 statu1表示已经读了 0 表示未读取
            tongzhiList: [],
            // 公告
            gonggaoList: [],
            // 订阅
            dingyueList: [],
            // 通知 公告 订阅 未读的数量
            tongzhiunReadNum: 0,
            gonggaounReadNum: 0,
            dingyueunReadNum: 0,
            // 总数
            total: 0,
            // 当前页
            currentPageNum: 1,
            // 每页大小
            pageSize: 6,
            type: 1,//1为消息通知 2为系统公告 3为订阅信息
            activeName: "0",
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo",
        }),
    },
    created() {
        this.getMessageList(1)
    },
    methods: {
        changePageNum(current) {
            //current是当前页数
            this.currentPageNum = current
            this.getMessageList(this.type)
        },
        handleClick(tab, event) {
            console.log(tab)
            switch (tab.index) {
                case '0':
                    this.getMessageList(1)
                    break
                case '1':
                    this.getMessageList(2)
                    break
                case '2':
                    this.getMessageList(3)
                    break
            }
        },
        getStatu(status) {
            if (status == 1) {
                return '已读'
            } else {
                return '未读'
            }
        },
        showMessage(val) {
            let that = this;
            console.log("点击,转跳");
            this.$router.push({
                path: "/workplace/message/detail/4057c346ac7c4a3d9c06dd920ec51af4?type=0"
            });
        },
        getMessageList(type) {
            this.type = type
            let params = {
                pageNum: this.currentPageNum,
                pageSize: this.pageSize,
                type: type,
            };
            let that = this
            this.$httpApi.get('/dev-api/message/list', params).then((res) => {
                console.log("消息详情:", res)
                that.total = res.total
                console.log("total总数" + that.total)
                if (res.code == 200) {
                    if (type === 1) {
                        that.tongzhiList = res.rows
                        that.tongzhiunReadNum = res.data
                        console.log("消息通知" + this.tongzhiList)
                    } else if (type === 2) {
                        that.gonggaoList = res.rows
                        that.tongzhiunReadNum = res.data
                        console.log("系统公告" + this.gonggaoList)
                    } else {
                        that.dingyueList = res.rows
                        that.tongzhiunReadNum = res.data
                        console.log("订阅消息" + this.dingyueList)
                    }
                }
            })
                .catch((err) => {

                });
        },
    },
};
</script>
<style lang="scss" scoped>
.message-container {
    height: 100%;

    .showpages {
        display: flex;
        flex-direction: row-reverse;
        border-bottom: 1px solid #f1f1f1;
    }

    .message-cell {
        display: flex;
        padding: 15px 10px;
        width: 100%;
        color: black;

        .message-title {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 600;
            text-align: left;
            flex-grow: 1;
        }

        .message-time {
            font-size: 10px;
            text-align: right;
            flex-grow: 1;
        }

        .hasRead {
            padding: 0 5px;
            border: 1px solid #999999;
            color: #999999;
            border-radius: 5px;
        }

        .unRead {
            padding: 0 5px;
            border: 1px solid #2d81ff;
            color: #2d81ff;
            border-radius: 5px;
        }
    }

    .message-cell:hover {
        background-color: #f1f1f1;
        color: #2d81ff;
    }

    .unRead {
        padding: 10px 0;
        border-bottom: 1px solid #f1f1f1;
    }
}
.wrap-header {
    margin-bottom: 10px;
    background-color: #ffffff;
}
::v-deep .el-tabs__header {
    padding-top: 5px;

    .el-tabs__nav-wrap {
        .el-tabs__item {
            padding-right: 20px;
            padding-left: 20px;
            font-size: 16px;
            font-weight: bold;

            &.is-active {
                color: $col-theme;
            }
        }
        .el-tabs__active-bar {
            background-color: $col-theme;
        }

        &::after {
            display: none;
        }
    }
}
.wrap-cotnent {
    position: relative;
    min-height: calc(100% - 70px);
    background-color: #ffffff;
}
</style>
